import DocumentDataService from '@/services/DocumentDataService';
import File from '@/types/File';
import BasicDataHelper from './BasicDataHelper';
import FileDataHelper from './FileDataHelper';

class DocumentDataHelper extends BasicDataHelper {
  static getObjectFromStore(store: any, val: string): File {
    /* UNDEFINED METHOD */
    return {} as File;
  }

  static prepareComponentData(component: any, file: string, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    FileDataHelper.prepareComponentData(component, file, (data: any) => {
      DocumentDataService.getFiltered(data.simulation.segment, data.simulation.product)
        .then((response: any) => {
          component.$store.dispatch('setDocumentTypes', response.data.data);
          successCallback(response.data.data);
        })
        .catch((e: Error) => { /* */ });
    }, (e: Error) => {
      failCallback(e);
    });
  }
}

export default DocumentDataHelper;
