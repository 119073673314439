
import { defineComponent } from 'vue';
import FileDataHelper from '@/helpers/FileDataHelper';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import DocumentDataHelper from '../../helpers/DocumentDataHelper';
import DocumentDataService from '../../services/DocumentDataService';
import File from '../../types/File';
import FinanceType from '../../types/FinanceType';
import Product from '../../types/Product';
import Segment from '../../types/Segment';
import DocumentType from '../../types/DocumentType';

export default defineComponent({
  name: 'DocumentUpload',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
  },
  data() {
    return {
      form: {
        inputs: {} as any,
        progress: {} as any,
        errors: {} as any,
      },
    };
  },
  created() {
    const vm = this;

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      DocumentDataHelper.prepareComponentData(
        this,
        this.$route.params.file as string,
        () => {
          Object.keys(this.$store.state.documentTypes).forEach((key: string) => {
            vm.form.inputs[this.$store.state.documentTypes[key].pid] = '';
            vm.form.progress[this.$store.state.documentTypes[key].pid] = 0;
            vm.form.errors[this.$store.state.documentTypes[key].pid] = null;
          });
        },
      );

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      FileDataHelper.prepareComponentData(
        this,
        this.$route.params.file as string,
        callback,
        (e: Error) => { /* */ },
      );
    },
    documentIsSelected(id: string) {
      return id in Object.keys(this.form.inputs);
    },
    upload(documentType: DocumentType, event: any) {
      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          const token = this.getAccessToken();

          // If cancel buttonhas been clicked
          if (event.target.files.length) {
            [this.form.inputs[documentType.pid]] = event.target.files;

            const mimeTypes = documentType.mime_types.split(',');

            if (!mimeTypes.includes(this.form.inputs[documentType.pid].type as string)) {
              this.form.errors[documentType.pid] = 'Ce type de fichier n\'est pas pris en charge.';
              this.form.inputs[documentType.pid] = '';
              this.form.progress[documentType.pid] = 0;
            } else if (this.form.inputs[documentType.pid].size > documentType.max_size * 1000) {
              this.form.errors[documentType.pid] = `La taille du fichier ne peut pas dépasser ${documentType.max_size} kilo-octets.`;
              this.form.inputs[documentType.pid] = '';
              this.form.progress[documentType.pid] = 0;
            } else {
              const formData = new FormData();
              /* const doc = document.querySelector(`#file`); */
              formData.append('document', this.form.inputs[documentType.pid]);
              formData.append('recaptcha', this.$store.state.recaptcha);

              DocumentDataService.upload(token, this.$store.state.selected.file, documentType, formData, (progress: any) => {
                this.form.progress[documentType.pid] = progress;
              }).then((response: any) => {
                this.form.errors[documentType.pid] = null;
              }).catch((e: any) => {
                if (e.response.code === 422) {
                  [this.form.errors[documentType.pid]] = e.response.data.data.document;
                  this.form.inputs[documentType.pid] = '';
                  this.form.progress[documentType.pid] = 0;
                } else {
                  /* this.form.errors[documentType.pid] = e.response.data.message; */
                  this.form.inputs[documentType.pid] = '';
                  this.form.progress[documentType.pid] = 0;
                }
              });
            }
          }
        });
    },
    trigger(documentt: DocumentType, event: any) {
      event.preventDefault();

      document.getElementById(documentt.pid)!.click();
    },
    goToNextStep(event: any) {
      event.preventDefault();
      // this.$store.dispatch('setDocumentUploadAsCompleted', true);

      this.goToNextScreen(this, {
        file: this.$store.state.selected.file.pid,
      });
    },
    size(value: number) {
      let unit = 'KO';
      let nbKo = value / 1000;

      if (nbKo > 1000) {
        unit = 'MO';
        nbKo /= 1000;
      }

      return `${nbKo.toFixed(2)} ${unit}`;
    },
    disabledSubmitButton() {
      let error = false;

      Object.keys(this.$store.state.documentTypes).forEach((key: string) => {
        if (this.form.progress[this.$store.state.documentTypes[key].pid] < 100) error = true;
      });

      return error;
    },
    clearInput(documentType: DocumentType) {
      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          this.form.inputs[documentType.pid] = '';
          this.form.progress[documentType.pid] = 0;
          const file = document.querySelector(`#${documentType.pid}`) as HTMLInputElement;
          file.value = '';

          const token = this.getAccessToken();
          DocumentDataService.clearInput(token, this.$store.state.selected.file, documentType, this.$store.state.recaptcha);
        });
    },
  },
});
